import { computed, reactive, ref } from "vue";
import { defineStore } from "pinia";
import type { LoadingStatus } from "./loading_status";
import StoragePolicyService from "@/core/services/StoragePolicyService";
import { ElMessage } from "element-plus";

export interface RedundancyScheme {
  scheme: 'rlnc' | 'replication'
}
export interface ECScheme extends RedundancyScheme {
  redundant_packets: number
}
export interface ReplicationScheme extends RedundancyScheme {
  replicas: number
}

export interface EncryptionScheme {
  scheme: 'aes'  
}
export interface AesScheme extends EncryptionScheme {
  block_mode: string
  key_size: number
}

export interface StoragePolicy {
  id: number
  name: string
  description?: string
  buckets_num?: number
  backends: number[]
  edge_devices?: number[]
  redundancy: RedundancyScheme
  encryption?: EncryptionScheme | null
  is_default?: boolean
}

export interface AttachedStoragePolicy {
  // Storage Policy name
  name: string
  s3_bucket_name: string
  redundancy: RedundancyScheme
  // Total number of storage locations (cloud+edge)
  locations_num: number 
  // Cloud location IDs
  backend_ids?: number[] 
  // Edge location IDs
  edge_device_ids?: number[] 
  // Underlying bucket IDs
  storage_bucket_ids?: number[]
  // Encryption scheme
  encryption?: EncryptionScheme | null
}


export const useStoragePolicyStore = defineStore("storage-policy", () => {
  const policies = reactive<StoragePolicy[]>([]);
  const policies_loading = reactive<LoadingStatus>({loading: false})

  const default_policy = computed<StoragePolicy|undefined>(_ => policies.find(p => p.is_default))

  const attached_policies = reactive<AttachedStoragePolicy[]>([]);
  const attached_policies_loading = reactive<LoadingStatus>({loading: false})

  async function load_policies(with_bucket_count: boolean = true): Promise<string | null> {
    policies_loading.loading = true
    return StoragePolicyService.list(with_bucket_count).then(({data}) => {
      policies.splice(0)
      data = data.map(policy => {
        policy.backends = policy.backends ? policy.backends : []
        return policy
      })
      policies.push(...data)
      policies_loading.loading = false
      return null
    }).catch(({response}) => {
      policies_loading.loading = false
      console.error(`Error loading storage policies: HTTP ${response.status}`, response.body)
      return "Error loading storage policies"
    })
  }
  
  async function load_all_attached(): Promise<null> {
    // Loads all SPs that are attached to a Bucket
    attached_policies_loading.loading = true
    return StoragePolicyService.list_all_attached().then(({data}) => {
      data = data.map(policy => {
        // Remove 'scheme=none' encryption
        if(policy.encryption && policy.encryption.scheme.toLowerCase() == 'none'){
          delete policy.encryption
        }
        policy.redundancy.scheme = policy.redundancy.scheme.toLowerCase()
        // Count total number of storage locations
        policy.locations_num = (policy.bucket_ids ? policy.bucket_ids.length : 0) + (policy.edge_device_ids ? policy.edge_device_ids.length : 0)
        // bucket_ids -> storage_bucket_ids
        policy.storage_bucket_ids = policy.bucket_ids; delete policy.bucket_ids
        // backends -> backend_ids
        policy.backend_ids = policy.backends; delete policy.backends
        return policy
      })
      attached_policies.splice(0)
      attached_policies.push(...data)
      attached_policies_loading.loading = false
    }).catch(err => {
      attached_policies_loading.loading = false
      console.error(`Error loading attached policies:`, err)
      ElMessage({
        type: "error",
        message: "Error loading Bucket Storage Policies"
      })
    })
  }

  return {
    policies,
    policies_loading,
    load_policies,
    default_policy,

    load_all_attached,
    attached_policies,
    attached_policies_loading,
  };
});
