import { reactive, ref } from "vue";
import { defineStore } from "pinia";
import type { LoadingStatus } from "./loading_status";
import StorageBackendService from "@/core/services/StorageBackendService";

export interface StorageProvider {
  id: number
  name: string
  logo_path: string
  service_name?: string
}

export interface StorageLocation {
  id: number
  name: string // Human-readable name
  provider_id: number
  provider?: StorageProvider
  countrycode: string
  country: string
  is_gdpr: boolean
  lat: number
  lng: number
  distance_km?: number
}

export interface EdgeDevice {
  id: number
  name: string
  description?: string
  storage_url: string
  region: string
}


export const useStorageLocationStore = defineStore("storage-location", () => {
  const locations = reactive<StorageLocation[]>([]);
  const edge_devices = reactive<EdgeDevice[]>([]);
  const locations_loading = reactive<LoadingStatus>({loading: false})


  async function load_locations(): Promise<string | null> {
    const providers = reactive<StorageProvider[]>([])
    const cloud_locations_tmp = reactive<StorageLocation[]>([]);
    const backends_with_distance = true
    const promises = [
      StorageBackendService.list_s3_backends(backends_with_distance).then(({data}) => { cloud_locations_tmp.push(...data) }),
      //StorageBackendService.list_edge_devices().then(({data}) => { edge_devices.splice(0); edge_devices.push(...data) }),
      StorageBackendService.list_s3_providers().then(({data}) => { providers.push(...data) })
    ]
    locations_loading.loading = true
    return Promise.all(promises).then(_ => {
      locations_loading.loading = false
      // update logos
      const google = providers.find(p => p.name.toLowerCase().includes('google'))
      google ? google.logo_path = "Google_Cloud_logo.svg.png" : false

      const ovh = providers.find(p => p.name.toLowerCase().includes('ovh'))
      ovh ? ovh.logo_path = "Logo_OVH.svg.png" : false

      const dunkel = providers.find(p => p.name.toLowerCase().includes('dunkel'))
      dunkel ? dunkel.logo_path = "dunkelclouddefault_logo_1.png" : false

      const azure = providers.find(p => p.name.toLowerCase().includes('microsoft'))
      azure ? azure.logo_path = "azure-logo.png" : false

      cloud_locations_tmp.forEach(loc => loc.provider = providers.find(p => p.id == loc.provider_id))
      
      locations.splice(0)
      locations.push(...cloud_locations_tmp)
      return null
    }).catch(({response}) => {
      locations_loading.loading = false
      console.error(`Error loading storage locations: HTTP ${response.status}`, response.body)
      return "Error loading storage locations"
    })
  }

  return {
    locations,
    edge_devices,
    load_locations,
    locations_loading,
  };
});