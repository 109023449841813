<template>
  <RouterView />
</template>

<script lang="ts">
import { defineComponent, inject, nextTick, onBeforeMount, onMounted } from "vue";
import { RouterView } from "vue-router";
import { useConfigStore } from "@/stores/config";
import { useThemeStore } from "@/stores/theme";
import { useBodyStore } from "@/stores/body";
import { themeConfigValue } from "@/core/helpers/config";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { useAuthStore } from "./stores/auth";
import { useUserStore } from "./stores/user";
import { useStorageLocationStore } from "./stores/storage_locations";
import { useStoragePolicyStore } from "./stores/storage_policy";
import { useBucketStore } from "./stores/buckets";
import type { Axios } from "axios";


export default defineComponent({
  name: "app",
  components: {
    RouterView,
  },
  setup() {

    const authStore = useAuthStore()
    const { load_user } = useUserStore()
    const themeStore = useThemeStore();
    const configStore = useConfigStore()
    const bodyStore = useBodyStore();
    const { load_policies } = useStoragePolicyStore()
    const { load_locations } = useStorageLocationStore()
    const { load_buckets } = useBucketStore()
    const axios = inject('axios') as Axios
    
    if(authStore.token) {
      // Set the auth token on page reload, if there is a cached one
      axios.defaults.headers.common['Authorization'] = `Bearer ${authStore.token}`

      // Reload the user
      load_user()
      // Load policies and locations
      load_policies()
      load_locations()
      
      const with_objects_count = true
      load_buckets(with_objects_count)
    }

    onBeforeMount(() => {
      configStore.overrideLayoutConfig();
      themeStore.setThemeMode(themeConfigValue.value);
    });

    onMounted(() => {
      nextTick(() => {
        initializeComponents();
        // Hide splash
        bodyStore.removeBodyClassName("page-loading");
      });
    });
  },
});
</script>

<style lang="scss">
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "apexcharts/dist/apexcharts.css";
@import "quill/dist/quill.snow.css";
@import "animate.css";
@import "sweetalert2/dist/sweetalert2.css";
@import "nouislider/distribute/nouislider.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "socicon/css/socicon.css";
@import "line-awesome/dist/line-awesome/css/line-awesome.css";
@import "dropzone/dist/dropzone.css";
@import "@vueform/multiselect/themes/default.css";
@import "prism-themes/themes/prism-shades-of-purple.css";
@import "element-plus/dist/index.css";

// Main demo style scss
@import "assets/fonticon/fonticon.css";
@import "assets/keenicons/duotone/style.css";
@import "assets/keenicons/outline/style.css";
@import "assets/keenicons/solid/style.css";
@import "assets/sass/element-ui.dark";
@import "assets/sass/plugins";
@import "assets/sass/style";

#app {
  display: contents;
}
</style>
