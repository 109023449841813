import type { User } from "@/stores/user";

const USER_KEY = "user";
const ls = window.localStorage



if(ls === undefined){
  throw "LocalStorage not available!"
}
/**
 * @description get cached User form localStorage
 */
export const getStoredUser = (): User | undefined => {
  const cached = ls.getItem(USER_KEY)
  return cached ? JSON.parse(cached) as User : undefined;
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const storeUser = (user: User): void => {
  ls.setItem(USER_KEY, JSON.stringify(user));
};

/**
 * @description remove token form localStorage
 */
export const deleteUser = (): void => {
  ls.removeItem(USER_KEY);
};

export default { getStoredUser, storeUser, deleteUser };
