const en = {
    loading: "Loading...",
    list: "List",
    new: "New",
    yes: "Yes",
    no: "No",
    
    switch_on: 'On',
    switch_off: 'Off',
    n_of_k: "{n} of {k}",
    
    storage_policy_mgmt: {
      replicas: 'replicas'
    },

    errors: {
      server_error: "Server error"
    },

    pages: {
      pages: "Pages",
      dashboard: "Dashboard",
      
      storage_policies_list: "List Policies",
      storage_policy_details: "Policy Details",
      storage_policy_create: "New Policy",
      storage_policy_create_serrano: "New Declarative Policy",
      storage_policy_edit: "Edit Policy",
      storage_locations: "Storage Locations",

      api_key_list: "List API Keys",
      api_key_details: "API Key Details",
      api_key_create: "New API Key",

      buckets_list: "List Buckets",
      bucket_details: "Bucket Details",
      bucket_create: "New Bucket",

      docs: "Documentation"
    },

    menu: {
      storage_policy: "Storage Policies",
      api_keys: "API Keys",
      buckets: "Buckets",
      docs: "Documentation"
    },


    storage_policy_wizard: {
      step1: "Basics",
      step2: "Reliability",
      step3: "Locations",
      step4: "Bucket Defaults",
      step5: "Overview",

      policy_name: "Name",
      description: "Description",
    },

    apikey_wizard: {
      step1: "Config",
      step2: "Overview",

      description: "Description",
    },
    
    redundancy_schemes: {
      ec: "Erasure Coding",
      ec_desc: "Balances performance and cost by encoding objects into redundant data fragments which are distributed across multiple locations.",
      
      replication: "Replication",
      replication_desc: "Ideal for very hot data where download speed is paramount. Stores multiple full copies of objects." 
    },

    languages: {
      label: "Languages",
      en: "English",
      dk: "Danish",
      de: "German"
    },

    auth: {
      signIn: "Sign In",
      signUp: "Sign-up",
      email_label: "Email",
      pass_label: "Password",
      forgot_pass_link: "Forgot password?",
      login_btn: "Login",
      

      errors: {
        heading: "Login Failed!",
        no_password: "Password missing",
        user_not_found: "Wrong email or password!",
        wrong_mfa_code: "Wrong code!",
        mfa_rate_limit: "Too many wrong codes, you can try again in {seconds} seconds!",
        unauthorized: "You are not allowed to log in here!",
        generic: "Login failed!"
      }
    },

    

    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
  }

export default en