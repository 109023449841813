import {  reactive } from "vue";
import { defineStore } from "pinia";
import type { LoadingStatus } from "./loading_status";
import { ElMessage } from "element-plus";
import type { AttachedStoragePolicy } from "./storage_policy";
import S3ApiService from "@/core/services/S3ApiService";

// SkyFlok S3 Dev user profile
export interface Bucket {
  id: number
  name: string
  namespace_id: number
  objects_num?: number
  versions_num?: number
  total_size?: number
  created?: Date
}

export interface S3Object {
  key: string
  name: string
  size?: number
  eTag?: string
  lastModified: Date
  loading: boolean
  percent?: number
  versions: Array<S3ObjectVersion | S3ObjectVersionDeleteMarker>
}

export interface S3ObjectVersionDeleteMarker {
  key: string
  versionId: string
  isLatest: boolean
  lastModified: Date
}
export interface S3ObjectVersion extends S3ObjectVersionDeleteMarker {
  size: number
  eTag?: string
}

export interface BucketWithPolicy extends Bucket {
  policy: AttachedStoragePolicy | null
}


export const BUCKET_NAME_MIN_LENGTH = 3
export const BUCKET_NAME_MAX_LENGTH = 63

export const useBucketStore = defineStore("bucket", () => {
  const buckets = reactive<Bucket[]>([])
  const buckets_loading = reactive<LoadingStatus>({loading: false})

  async function load_buckets(with_objects_count: boolean | null = true): Promise<null> {
    buckets_loading.loading = true
    return S3ApiService.list_buckets(with_objects_count).then(({data}) => {
      data.forEach(bucket => {
        bucket.created = new Date(bucket.created)
      });
      buckets_loading.loading = false
      buckets.splice(0)
      buckets.push(...data)
    }).catch(({response}) => {
      buckets_loading.loading = false
      console.error(`Error loading buckets:`, response)
      ElMessage({
        type: 'error',
        message: 'Error loading Buckets'
      })
    })
  }

  const access_name = (access: string) => {
    switch(access){
      case 'read': return "Read Only"
      case "full": return "Full Access"
      default: return ""
    }
  }
  
  const bucket_name = (name) => 
    name === "__new__" ? "New Buckets" : name
  

  return {
    buckets,
    buckets_loading,
    load_buckets,
    access_name, 
    bucket_name,
  };
});
