/**
 * @description Manage Storage Policies 
 */
class StoragePolicyService {
    /**
     * @description Service base URL (without trailing `/`)
     */
    public static base_url: string = import.meta.env.VITE_APP_STORAGE_POLICY_SERVICE_URL
    public static axios: any

    public static init (axiosInstance: any) {
      StoragePolicyService.axios = axiosInstance 
    }

    public static list(with_bucket_count: boolean | undefined) {
      let args = {}
      if(with_bucket_count != null){
        args['with_bucket_count'] = Number(with_bucket_count)
      }
      return StoragePolicyService.axios.get(StoragePolicyService.base_url+'/', {params: args})
    }

    public static insert(payload) {
      return StoragePolicyService.axios.post(StoragePolicyService.base_url+'/', payload)
    }

    public static update(policy_id, payload) {
      return StoragePolicyService.axios.put(StoragePolicyService.base_url+'/'+policy_id, payload)
    }

    public static delete(policy_id) {
      return StoragePolicyService.axios.delete(StoragePolicyService.base_url+'/'+policy_id)
    }

    public static list_all_attached() {
      return StoragePolicyService.axios.get(StoragePolicyService.base_url+'/list_attached')
    }

    public static make_default(storage_policy_name) {
      const payload = {
        storage_policy_name: storage_policy_name
      }
      return StoragePolicyService.axios.put(StoragePolicyService.base_url+'/make_default', payload)
    }


}

export default StoragePolicyService
