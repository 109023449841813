import { ref, reactive, computed } from "vue";
import { defineStore } from "pinia";
import AuthService from "@/core/services/AuthService";
import AuthTokenStore from "@/core/localStorage/AuthTokenLS";
import type { Nullable } from "element-plus/es/components/cascader-panel/src/node";

// Which SkyFlok User roles are allowed to log in
const allowed_roles = ["S3_DEV"]

export interface LoginCredentials {
  email: string;
  password: string;
  mfa_code: Nullable<string>;
}

export interface LoginError {
  // i18n key
  key: string
  // i18n string params
  params?: any
}

export const useAuthStore = defineStore("auth", {
  state: () => {
  // The user's roles
  const roles = reactive<string[]>([]);
  // Access token (ini from localStorage, so it's picked up on page reloads)
  const token = ref<Nullable<string>>(AuthTokenStore.getToken());
  
  function purgeAuth() {
    roles.splice(0)
    token.value = null
    AuthTokenStore.destroyToken();
  }




  /**
   * Logs in the user with the given credentials and checks they have
   * a Role that allows access to this Developer Portal.
   * 
   * If the login is successful (`null` is returned), the access token is
   * saved persistently via AuthTokenStore and the user's roles are
   * available under the `roles` property.
   * 
   * @param credentials  
   * @returns Error message if there was a problem, or null if login succeeded.
   */
  async function login(credentials: LoginCredentials) : Promise<LoginError | null> {

    const login_err = await AuthService.login(credentials.email, credentials.password)
      .then(({ data }) => {
        // The whole response data is the plaintext token (not a JSON object)
        token.value = data
      })
      .catch(({ response }) => {
        purgeAuth()
        switch(response.status){
          case 401: return {key:'auth.errors.unauthorized'}
          case 403: return {key: 'auth.errors.wrong_mfa_code'}
          case 404: return {key: 'auth.errors.user_not_found'}
          case 429: return {key: 'auth.errors.mfa_rate_limit', params: {seconds: response.data.seconds_to_wait}}
          case 500: return {key: 'errors.server_error'}
          default: return {key: 'errors.generic'}
        }
      });

    if(login_err || !token.value) { return login_err }

    // Load roles
    await verifyAuth()
    if(!token.value) {
      // Should not fail after a fresh login
      // TODO report issue to FrontendSupport
      return {key: 'errors.server_error'}
    }

    // Check that the user has any of the allowed roles
    if (!_is_user_role_allowed()){
      purgeAuth()
      return {key:'auth.errors.unauthorized'}
    } 
    // Auth successful, save the token
    AuthTokenStore.saveToken(token.value)
    return null
  }


  // Verify that the stored token is still valid.
  // Sets `isAuthenticated` and `roles`
  async function verifyAuth() : Promise<void> {
    if(!token.value){
      purgeAuth()
      return
    }
    roles.splice(0)
    await AuthService.verify_token(token.value)
      .then(({data}) => {
        // Token is still OK, store roles
        roles.push(...data.roles)
      })
      .catch(_ => { purgeAuth() });
  }
  
  // Check if the user has any of the allowed roles
  function _is_user_role_allowed() : boolean {
    for(let i=0 ; i<allowed_roles.length ; ++i){
      if(roles.indexOf(allowed_roles[i]) >= 0) return true
    }
    return false
  }
  
  // TODO implement me
  function forgotPassword(email){
    console.error("Forgotten password is not implemented")
    return false
  }

  function logout() {
    purgeAuth();
  }

  return {
    token,
    roles,
    login,
    logout,
    verifyAuth,
  }
},
getters: {
  isAuthenticated: (state) => state.token !== null
}
});