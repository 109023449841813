import { createApp } from "vue";
import { createPinia } from "pinia";
import { Tooltip } from "bootstrap";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";

import router from "./router";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from "./stores/user";

import axios from "axios"
import VueAxios from "vue-axios"

//imports for app initialization
import AuthService from "@/core/services/AuthService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { initKtIcon } from "@/core/plugins/keenthemes";

import "@/core/plugins/prismjs";
import UserService from "./core/services/UserService";
import StoragePolicyService from "./core/services/StoragePolicyService";
import StorageBackendService from "./core/services/StorageBackendService";
import NamespaceService from "./core/services/NamespaceService";
import S3ApiService from "./core/services/S3ApiService";
import S3Gateway from "./core/services/S3Gateway";
import MigrationService from "./core/services/MigrationService";

// Sentry.init({
//   dsn: "https://eb994eb13ae78cdfbac8e279f7e06e1d@o278410.ingest.sentry.io/4506813736550400",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/s3\.sky-flok\.com\/*/, /^https:\/\/*\.appspot\.com/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.use(ElementPlus);

// Add a response interceptor
axios.interceptors.response.use(response => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response;
}, error => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (error.response.status == 401){
    // TODO except for login resoponse?
    console.log("401 response received, logging out")
    // Remove the auth header, this token won't work any more
    delete axios.defaults.headers.common['Authorization']
    const authstore = useAuthStore();
    authstore.logout()
    // Remove cached user
    const userstore = useUserStore()
    userstore.delete_cached_user()
    // Redirect to login
    router.push({ name: 'sign-in' });
    // TODO show error?
  }
  
  return Promise.reject(error);
});

app.use(VueAxios, axios)
// Make the axios instance available via const axios = inject('axios') in components
app.provide('axios', app.config.globalProperties.axios)

// Pass the axios instance to all Services that require the SkyFlok Token
AuthService.init(app.axios)
UserService.init(app.axios)
StoragePolicyService.init(app.axios)
StorageBackendService.init(app.axios)
NamespaceService.init(app.axios)
S3ApiService.init(app.axios)
S3Gateway.init(app.axios)
MigrationService.init(app.axios)

initApexCharts(app);
initInlineSvg(app);
initKtIcon(app);
initVeeValidate();

app.use(i18n);

app.directive("tooltip", (el) => {
  new Tooltip(el);
});

app.mount("#app");