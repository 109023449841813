import {
  createRouter,
  createWebHashHistory,
  type RouteRecordRaw,
} from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import { useConfigStore } from "@/stores/config";
import i18n from "@/core/plugins/i18n";

const { t } = i18n.global

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/buckets",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      /*
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: t("pages.dashboard"),
        },
      },
      */


      /* Storage Policies */
      {
        path: "/storage_policy",
        name: "storage_policies_list",
        meta: {
          pageTitle: "Storage Policies",
          breadcrumbs: [{url: '/storage_policy', label: "Storage Policies"}],
        },
        component: () => import("@/views/storage_policy/StoragePolicyList.vue")
      },
      {
        path: "/storage_policy/details/:sp_key",
        name: "storage_policy_details",
        meta: {
          pageTitle: t("pages.storage_policy_details"),
          breadcrumbs: [{url: '/storage_policy', label: "Storage Policies"}, "Details"],
        },
        component: () => import("@/views/storage_policy/StoragePolicyDetails.vue")
      },
      {
        path: "/storage_policy/edit/:sp_key",
        name: "storage_policy_edit",
        meta: {
          pageTitle: t("pages.storage_policy_edit"),
          breadcrumbs: [{url: '/storage_policy', label: "Storage Policies"}, "Edit"],
        },
        component: () => import("@/views/storage_policy/StoragePolicyWizard.vue")
      },
      {
        path: "/storage_policy/new",
        name: "storage_policy_wizard",
        meta: {
          pageTitle: t("pages.storage_policy_create"),
          breadcrumbs: [{url: '/storage_policy', label: "Storage Policies"}, "New"],
        },
        component: () => import("@/views/storage_policy/StoragePolicyWizard.vue")
      },

      {
        path: "/storage_policy/new",
        name: "storage_policy_wizard",
        meta: {
          pageTitle: t("pages.storage_policy_create"),
          breadcrumbs: [{url: '/storage_policy', label: "Storage Policies"}, "New"],
        },
        component: () => import("@/views/storage_policy/StoragePolicyWizard.vue")
      },

      {
        path: "/storage_policy/locations",
        name: "storage_locations",
        meta: {
          pageTitle: t("pages.storage_locations"),
          breadcrumbs: [{url: '/storage_policy', label: "Storage Policies"}],
        },
        component: () => import("@/views/storage_policy/StorageLocationsList.vue")
      },

      /* API Keys */
      {
        path: "/api_keys",
        name: "apikeys-list",
        meta: {
          pageTitle: "API Keys",
          breadcrumbs: [{url: '/api_key', label: "API Keys"}],
        },
        component: () => import("@/views/api_key/ApiKeysList.vue")
      },
      {
        path: "/api_keys/new",
        name: "apikeys-create",
        meta: {
          pageTitle: "New API Key",
          breadcrumbs: [{url: '/api_key', label: "API Keys"}, "New"],
        },
        component: () => import("@/views/api_key/CreateApiKey.vue")
      },
      

      /* Buckets */
      {
        path: "/buckets",
        name: "buckets-list",
        meta: {
          pageTitle: "Buckets",
          breadcrumbs: [{url: '/buckets', label: "Buckets"}],
        },
        component: () => import("@/views/bucket/BucketsList.vue")
      },
      {
        path: "/buckets/new",
        name: "bucket-create",
        meta: {
          pageTitle: "New Bucket",
          breadcrumbs: [{url: '/buckets', label: "Buckets"}, "New"],
        },
        component: () => import("@/views/bucket/BucketWizard.vue")
      },
      
      {
        path: "/buckets/:bucket_name",
        name: "bucket-details",
        meta: {
          pageTitle: "Bucket Details",
          breadcrumbs: [{url: '/buckets', label: "Buckets"}, "Details"],
        },
        component: () => import("@/views/bucket/BucketDetails.vue"),
        children: [
          {
            path: "objects",
            name: "bucket-contents",
            component: () => import("@/views/bucket/details/Objects.vue"),
          },
          {
            path: "policy",
            name: "bucket-storage-policy",
            component: () => import("@/views/bucket/details/Policy.vue"),
          },
          {
            path: "access-control",
            name: "bucket-access-control",
            component: () => import("@/views/bucket/details/AccessControl.vue"),
          },
          {
            path: "apikeys",
            name: "bucket-apikeys",
            component: () => import("@/views/bucket/details/ApiKeys.vue"),
          },
          {
            path: "cors",
            name: "bucket-cors",
            component: () => import("@/views/bucket/details/Cors.vue"),
          },
          {
            path: "config",
            name: "bucket-config",
            component: () => import("@/views/bucket/details/Configuration.vue"),
          },
          {
            path: "migrations",
            name: "bucket-migrations",
            component: () => import("@/views/bucket/details/Migrations.vue"),
          },
          
        ],
      },

      {
        path: "/docs",
        name: "documentation",
        meta: {
          pageTitle: "Documentation",
          breadcrumbs: ["Documentation"],
        },
        component: () => import("@/views/documentation/Docs.vue"),
        children: [
          {
            path: "quickstart",
            name: "docs-quickstart",
            component: () => import("@/views/documentation/pages/Quickstart.vue"),
          },
          {
            path: "features",
            name: "docs-features",
            component: () => import("@/views/documentation/pages/S3Features.vue"),
          },
          {
            path: "tools",
            name: "docs-tools",
            component: () => import("@/views/documentation/pages/Tools.vue"),
          },
          
          
        ],
      },

      /*
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        meta: {
          breadcrumbs: ["Crafted", "Account"],
        },
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
            meta: {
              pageTitle: "Settings",
            },
          },
        ],
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        meta: {
          breadcrumbs: ["Pages", "Profile"],
        },
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
            meta: {
              pageTitle: "Projects",
            },
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
            meta: {
              pageTitle: "Campaigns",
            },
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
            meta: {
              pageTitle: "Documents",
            },
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
            meta: {
              pageTitle: "Connections",
            },
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
            meta: {
              pageTitle: "Activity",
            },
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
        meta: {
          pageTitle: "Horizontal",
          breadcrumbs: ["Pages", "Wizard"],
        },
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
        meta: {
          pageTitle: "Vertical",
          breadcrumbs: ["Pages", "Wizard"],
        },
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        meta: {
          breadcrumbs: ["Crafted", "Account"],
        },
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
            meta: {
              pageTitle: "Overview",
            },
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
            meta: {
              pageTitle: "Settings",
            },
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
        meta: {
          pageTitle: "Getting Started",
          breadcrumbs: ["Apps", "Customers"],
        },
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
        meta: {
          pageTitle: "Customers Listing",
          breadcrumbs: ["Apps", "Customers"],
        },
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
        meta: {
          pageTitle: "Customers Details",
          breadcrumbs: ["Apps", "Customers"],
        },
      },
      {
        path: "/apps/subscriptions/getting-started",
        name: "apps-subscriptions-getting-started",
        component: () =>
          import("@/views/apps/subscriptions/GettingStarted.vue"),
        meta: {
          pageTitle: "Getting Started",
          breadcrumbs: ["Apps", "Subscriptions"],
        },
      },
      {
        path: "/apps/subscriptions/subscription-list",
        name: "apps-subscriptions-subscription-list",
        component: () =>
          import("@/views/apps/subscriptions/SubscriptionList.vue"),
        meta: {
          pageTitle: "Getting Started",
          breadcrumbs: ["Apps", "Subscriptions"],
        },
      },
      {
        path: "/apps/subscriptions/add-subscription",
        name: "apps-subscriptions-add-subscription",
        component: () =>
          import("@/views/apps/subscriptions/AddSubscription.vue"),
        meta: {
          pageTitle: "Add Subscription",
          breadcrumbs: ["Apps", "Subscriptions"],
        },
      },
      {
        path: "/apps/subscriptions/view-subscription",
        name: "apps-subscriptions-view-subscription",
        component: () =>
          import("@/views/apps/subscriptions/ViewSubscription.vue"),
        meta: {
          pageTitle: "View Subscription",
          breadcrumbs: ["Apps", "Subscriptions"],
        },
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
        meta: {
          pageTitle: "Calendar",
          breadcrumbs: ["Apps"],
        },
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Private Chat",
          breadcrumbs: ["Apps", "Chat"],
        },
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
        meta: {
          pageTitle: "Group Chat",
          breadcrumbs: ["Apps", "Chat"],
        },
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
        meta: {
          pageTitle: "Drawer Chat",
          breadcrumbs: ["Apps", "Chat"],
        },
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
        meta: {
          pageTitle: "Invite Friends",
          breadcrumbs: ["Crafted", "Modals", "General"],
        },
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
        meta: {
          pageTitle: "View User",
          breadcrumbs: ["Crafted", "Modals", "General"],
        },
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
        meta: {
          pageTitle: "Upgrade Plan",
          breadcrumbs: ["Crafted", "Modals", "General"],
        },
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
        meta: {
          pageTitle: "Share And Earn",
          breadcrumbs: ["Crafted", "Modals", "General"],
        },
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
        meta: {
          pageTitle: "New Target",
          breadcrumbs: ["Crafted", "Modals", "Forms"],
        },
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
        meta: {
          pageTitle: "New Card",
          breadcrumbs: ["Crafted", "Modals", "Forms"],
        },
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
        meta: {
          pageTitle: "New Address",
          breadcrumbs: ["Crafted", "Modals", "Forms"],
        },
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
        meta: {
          pageTitle: "Create Api Key",
          breadcrumbs: ["Crafted", "Modals", "Forms"],
        },
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
        meta: {
          pageTitle: "Two Factory Auth",
          breadcrumbs: ["Crafted", "Modals", "Wizards"],
        },
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
        meta: {
          pageTitle: "Create App",
          breadcrumbs: ["Crafted", "Modals", "Wizards"],
        },
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
        meta: {
          pageTitle: "Create Account",
          breadcrumbs: ["Crafted", "Modals", "Wizards"],
        },
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
        meta: {
          pageTitle: "Lists",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
        meta: {
          pageTitle: "Statistics",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
        meta: {
          pageTitle: "Charts",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
        meta: {
          pageTitle: "Mixed",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
        meta: {
          pageTitle: "Tables",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
        meta: {
          pageTitle: "Feeds",
          breadcrumbs: ["Crafted", "Widgets"],
        },
      },
      */
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
      /*
      */
    ],
  },
  /*
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    meta: {
      pageTitle: "Multi-step Sign up",
    },
  },
  */

  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const configStore = useConfigStore();

  // Set page title
  document.title = `${to.meta.pageTitle} | ${import.meta.env.VITE_APP_NAME}`;

  // Reset config to initial state
  configStore.resetLayoutConfig();

  // Verify auth token on page reload, if the page is authenticated
  if(to.meta.middleware == "auth" && (!from || !from.matched || !from.matched.length)){
    /* Verify token on each navigation
    authStore.verifyAuth().then(_ => {
      // Reload the cached user, if any
      // userStore.load_user()
    });
    */
  }

  // Verify auth if the page is protected
  if (to.meta.middleware == "auth" && !authStore.isAuthenticated) {
    // clear up the stored auth
    authStore.logout()
    next({ name: "sign-in" });
  }
  else {
    // OK, navigate to the requested destination
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
