/**
 * @description Manage Storage Policies 
 */
class NamespaceService {
    /**
     * @description Service base URL (without trailing `/`)
     */
    public static base_url: string = import.meta.env.VITE_APP_NAMESPACE_SERVICE_URL
    public static axios: any

    public static init (axiosInstance: any) {
      NamespaceService.axios = axiosInstance 
    }

    public static list_buckets(with_object_count: boolean | null) {
      const args = with_object_count !== null ? `?with_object_count=${((with_object_count as boolean) ? '1' : '0')}` : ''
      return NamespaceService.axios.get(`${NamespaceService.base_url}/s3/buckets${args}`)
    }

    public static create_bucket(bucket_name: string, payload: Object) {
      return NamespaceService.axios.post(`${NamespaceService.base_url}/s3/${bucket_name}`, payload)
    }


}

export default NamespaceService
