/**
 * @description Manage entities related to S3
 */
class MigrationService {
  /**
   * @description Service base URL (without trailing `/`)
   */
  public static base_url: string = import.meta.env.VITE_APP_MIGRATION_SERVICE_URL
  
  
  public static axios: any

  public static init (axiosInstance: any) {
    if(!MigrationService.base_url){
      throw "MigrationService base URL not set in env vars (VITE_APP_MIGRATION_SERVICE_URL)"
    }
    MigrationService.axios = axiosInstance 
  }

  public static list_migrations(namespace_id: number) {
    return MigrationService.axios.get(`${MigrationService.base_url}/migrations?namespace_id=${namespace_id}`)
  }

  public static get_migration(uuid: string) {
    return MigrationService.axios.get(`${MigrationService.base_url}/migration?uuid=${uuid}`)
  }

  public static get_migrated_versions(migration_uuid: string) {
    return MigrationService.axios.get(`${MigrationService.base_url}/migration/versions?uuid=${migration_uuid}`)
  }

  public static estimate_migration(payload: any) {
    return MigrationService.axios.post(MigrationService.base_url+'/migrations/estimate', payload)
  }

  public static start_migration(payload: any) {
    return MigrationService.axios.post(MigrationService.base_url+'/migrations', payload)
  }

}

export default MigrationService
