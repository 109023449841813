

/**
 * @description User 
 */
class UserService {
    /**
     * @description Service base URL (without trailing `/`)
     */
    public static base_url: string = import.meta.env.VITE_APP_USER_SERVICE_URL

    public static axios: any

    public static init (axiosInstance: any) {
      UserService.axios = axiosInstance 
    }

    // Loads the user with the stored auth token
    public static get_me() {
      return UserService.axios.get(UserService.base_url + '/users/me')
    }

    // Loads the user with the stored auth token
    public static get_my_company() {
      return UserService.axios.get(UserService.base_url + '/companies/me')
    }

}

export default UserService
