const TOKEN_KEY = "skyflok_token" as string;
const ls = window.localStorage

if(ls === undefined){
  throw "LocalStorage not available!"
}
/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return ls.getItem(TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  ls.setItem(TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  ls.removeItem(TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
