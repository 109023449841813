/**
 * @description Manage entities related to S3
 */
class S3Gateway {
  /**
   * @description Service base URL (without trailing `/`)
   */
  public static base_url: string = import.meta.env.VITE_APP_S3_GATEWAY_URL
  
  
  public static axios: any

  public static init (axiosInstance: any) {
    S3Gateway.axios = axiosInstance 
  }

  public static download_object(bucket_name: string, object_name: string, progress_callback: any = undefined) {
    const config = {
      responseType: 'blob'
    }
    if(progress_callback){
      config['onDownloadProgress'] = progress_callback
    }
    return S3Gateway.axios.get(`${S3Gateway.base_url}/${bucket_name}/${object_name}`, config)
  } 

  public static upload_object(bucket_name: string, object_name: string, data: any, progress_callback: any = undefined) {
    const config = {}
    if(progress_callback){
      config['onUploadProgress'] = progress_callback
    }
    return S3Gateway.axios.put(`${S3Gateway.base_url}/${bucket_name}/${object_name}`, data=data, config)
  } 


}

export default S3Gateway
