import { computed, ref } from "vue";
import { defineStore } from "pinia";
import type { Nullable } from "element-plus/es/components/cascader-panel/src/node";
import UserService from "@/core/services/UserService";
import UserStoreLS from "@/core/localStorage/UserLS";
import { useResetForm } from "vee-validate";


// SkyFlok S3 Dev user profile
export interface User {
  id: number,
  name: string;
  email_address: string;
  company_name: string;
  roles: string[];
  avatar_url: string | null;
  job_title: string | null;
  company: {
    id: number
    name: string
    config: any,
    lang?: string
  }
}

export const useUserStore = defineStore("user", () => {
  const user = ref<User | undefined>(UserStoreLS.getStoredUser());

  const is_serrano_team = computed<Boolean>(_ => user.value && user.value.company && user.value.company.config.is_serrano_team)

  /**
   * Loads the logged in user profile into the `user` field of userStore
   * @returns An error string if there was a problem. Nothing otherwise
   */
  async function load_user() : Promise<string | undefined> {
    return UserService.get_me()
      .then(({data}) => {
        user.value = data as User
        UserStoreLS.storeUser(user.value)

        return UserService.get_my_company()
      })
      .then(({data}) => {
        if(user.value) user.value.company = data
      })
      .catch(({response}) => {
        console.error(response)
        // TODO return error message based on response status
        return "Error"
      })
  }

  /**
   * Deletes the cached User from localStorage
   */
  function delete_cached_user() {
    UserStoreLS.deleteUser()
  }


  return {
    user,
    is_serrano_team,
    load_user,
    delete_cached_user
  };
});
