/**
 * @description Manage Storage Policies 
 */
class StorageBackendService {
    /**
     * @description Service base URL (without trailing `/`)
     */
    public static base_url: string = import.meta.env.VITE_APP_STORAGE_BACKEND_SERVICE_URL
    public static axios: any

    public static init (axiosInstance: any) {
      StorageBackendService.axios = axiosInstance 
    }

    public static list_s3_backends(with_distance: boolean = false) {
      const url_params = with_distance ? {'with_distance': true} : null
      return StorageBackendService.axios.get(StorageBackendService.base_url + '/backends', { params: url_params })
    }

    public static list_s3_providers() {
      return StorageBackendService.axios.get(StorageBackendService.base_url + "/providers")
    }

}

export default StorageBackendService
