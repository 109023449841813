import platform from "platform";

/**
 * @description service to call HTTP request via Axios
 */
class AuthService {
    /**
     * @description Service base URL (without trailing `/`)
     */
    public static base_url: string = import.meta.env.VITE_APP_AUTH_SERVICE_URL

    public static axios: any

    public static init (axiosInstance: any) {
      AuthService.axios = axiosInstance
    }

    public static login(email: string, pass: string) {
      const payload = new FormData()
      payload.append("email_address", email)
      payload.append("password", pass)
      payload.append("client_os", platform.os.family)
      payload.append("client_name", platform.name)
      payload.append("client_version", platform.version)
      return AuthService.axios.post(AuthService.base_url + '/login', payload)
    }

    public static verify_token(token: string) {
      return AuthService.axios.get(AuthService.base_url + '/authenticate', {headers: {Authorization: `Bearer ${token}`}})
    }

}

export default AuthService
