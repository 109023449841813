/**
 * @description Manage entities related to S3
 */
class S3ApiService {
  /**
   * @description Service base URL (without trailing `/`)
   */
  //public static base_url: string = import.meta.env.VITE_APP_S3_API_SERVICE_URL
  public static base_url: string = "https://s3api-dot-storageaggregator-dev.ey.r.appspot.com"
  public static gateway_base_url: string = import.meta.env.VITE_APP_S3_GATEWAY_URL
  
  
  public static axios: any

  public static init (axiosInstance: any) {
    S3ApiService.axios = axiosInstance 
  }

  public static list_apikeys() {
    return S3ApiService.axios.get(S3ApiService.base_url+'/apikey')
  }
  
  public static insert_apikey(payload: object) {
    return S3ApiService.axios.post(S3ApiService.base_url+'/apikey', payload)
  }
  
  public static delete_apikey(access_key: string) {
    return S3ApiService.axios.delete(S3ApiService.base_url+'/apikey?access_key='+access_key)
  }

  public static enable_disable_apikey(payload: object) {
    return S3ApiService.axios.put(`${S3ApiService.base_url}/apikey/disable`, payload)
  }


  public static list_buckets(with_object_count: boolean | null = null) {
    return S3ApiService.axios.get(S3ApiService.base_url+'/bucket'+(with_object_count !== null ? `?with_object_count=${with_object_count}` : ''))
  }
  
  public static create_bucket(payload: object) {
    return S3ApiService.axios.post(S3ApiService.base_url+'/bucket', payload)
  }
  public static delete_bucket(bucket_name: string) {
    return S3ApiService.axios.delete(`${S3ApiService.base_url}/bucket/${bucket_name}`)
  }

  public static list_bucket_objects = (bucket_name: string, continuationToken: string = '', prefix: string = '') => {
    const query_parts : string[] = []
    if(continuationToken.length){
      query_parts.push(`continuation-token=${continuationToken}`)
    }
    if(prefix.length){
      query_parts.push(`prefix=${prefix}`)
    }
    
    const query = query_parts.length ? '?'+query_parts.join('&') : ''
    return S3ApiService.axios.get(`${S3ApiService.base_url}/bucket/${bucket_name}/objects${query}`)
  }

  public static list_bucket_versions = (bucket_name: string, key_marker: string = '', version_id_marker: string = '', prefix: string = '') => {
    const query_parts : string[] = []
    if(key_marker.length) query_parts.push(`key-marker=${key_marker}`)
    if(version_id_marker.length) query_parts.push(`version-id-marker=${version_id_marker}`)
    if(prefix.length) query_parts.push(`prefix=${prefix}`)
    
    const query = query_parts.length ? '?'+query_parts.join('&') : ''
    return S3ApiService.axios.get(`${S3ApiService.base_url}/bucket/${bucket_name}/versions${query}`)
  }
    
  public static delete_object(bucket_name: string, object_name: string, version_id: string = '') {
    let query_params = ""
    if (version_id.length) {
      query_params = `?versionId=${version_id}`
    }
    return S3ApiService.axios.delete(`${S3ApiService.base_url}/bucket/${bucket_name}/${object_name}${query_params}`)
  } 

  public static is_bucket_name_used(bucket_name: string) {
    return S3ApiService.axios.get(`${S3ApiService.base_url}/bucket/is_used?name=${bucket_name}`)
  } 

  public static download_object(bucket_name: string, object_name: string) {
    return S3ApiService.axios.get(`${S3ApiService.gateway_base_url}/${bucket_name}/${object_name}`, {responseType: 'blob'})
  } 


}

export default S3ApiService
